import { api } from '@approvalmax/data';
import { getEnvByHostname } from 'config';

export const {
    useGetRequest,
    useGetRequestLegacy,
    useMutate,
    useMutateLegacy,
    getApiUrl,
    setApiUrlByEnv,
    setApiUrl,
    resetApiUrl,
} = api.init('adminPanel', getEnvByHostname(window.location.hostname) || 'dev04');
