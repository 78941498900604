import { Button, Flex, Form, Popup, Switch, TextField, toast } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';
import { useForm } from 'react-hook-form';

import { OcrAttachmentParams } from '../OcrAttachmentSection/OcrAttachmentSection.types';
import { messages } from './SelectOcrAttachmentPopupContent.messages';
import { SelectOcrAttachmentPopupContentProps } from './SelectOcrAttachmentPopupContent.types';

export const SelectOcrAttachmentPopupContent: FC<SelectOcrAttachmentPopupContentProps> = memo((props) => {
    const { closePopup, requestDetails, ocrAttachmentParams, setOcrAttachmentParams } = props;

    const form = useForm<OcrAttachmentParams>({
        defaultValues: ocrAttachmentParams,
    });

    const showRawModel = form.watch('showRawModel');

    const onSubmit = form.handleSubmit((data) => {
        if (
            requestDetails?.rawData.attachments.some(
                (attachment) => attachment.ocrStatus && attachment.id === data.ocrAttachmentId
            )
        ) {
            setOcrAttachmentParams(data);
            closePopup();
        } else {
            toast.error(messages.notFoundErrorText);
        }
    });

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Popup.Header
                title={messages.title}
                actions={
                    <Button color='blue80' type='submit' title={messages.submitButtonTitle}>
                        {messages.submitButtonTitle}
                    </Button>
                }
            />

            <Popup.Body>
                <Flex inline direction='column' spacing='16'>
                    <Switch name='showRawModel'>{showRawModel ? messages.rawModel : messages.amModel}</Switch>

                    <TextField name='ocrAttachmentId' placeholder={messages.placeholderText} />
                </Flex>
            </Popup.Body>
        </Form>
    );
});

SelectOcrAttachmentPopupContent.displayName = 'SelectOcrAttachmentPopupContent';
