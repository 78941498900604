import { QueryOptions, QueryParams } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import {
    UseAmaxPayConsentsParams,
    UseAmaxPayConsentsPathParams,
    UseAmaxPayConsentsResponse,
} from './useAmaxPayConsents.types';

export const useGetAmaxPayConsents = (
    pathParams: QueryParams<UseAmaxPayConsentsPathParams>,
    params: UseAmaxPayConsentsParams,
    queryOptions?: QueryOptions<UseAmaxPayConsentsResponse>
) => {
    return useGetRequest<UseAmaxPayConsentsResponse>(amaxPayApiPaths.openBankingConsents, {
        pathParams,
        params,
        queryOptions,
    });
};
