export type Env = 'dev01' | 'dev02' | 'dev03' | 'dev04' | 'staging' | 'production';

const HOSTNAME_TO_ENV_MAP: Record<string, Env> = {
    'admin-dev01.amaxinternal.com': 'dev01',
    'admin-dev02.amaxinternal.com': 'dev02',
    'admin-dev03.amaxinternal.com': 'dev03',
    'admin-dev04.amaxinternal.com': 'dev04',
    'testadmin.approvalmax.com': 'staging',
    'admin.approvalmax.com': 'production',
};

const getDevEnvConfig = (
    env: Env
): {
    apiBaseUrl: string;
    appUrl: string;
    accountUrl: string;
    auditLogsUrl: string;
} => {
    const isNewDevDomain = window.location?.hostname.endsWith('.amaxinternal.com');
    const envNumber = env.match('[0-9]+$')![0];

    return isNewDevDomain
        ? {
              apiBaseUrl: `https://admin-api-${env}.amaxinternal.com`,
              appUrl: `https://app-${env}.amaxinternal.com`,
              accountUrl: `https://account-${env}.amaxinternal.com`,
              auditLogsUrl: `https://portal.azure.com#@24d5fd06-86ce-40bf-be09-837627e3385d/blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2F88ad0ef2-9fa6-4c5a-b7a4-54a81e804f5f%2Fresourcegroups%2F${env}%2Fproviders%2Fmicrosoft.operationalinsights%2Fworkspaces%2Fam-${env}-workspace/source/LogsBlade.AnalyticsShareLinkToQuery/q/H4sIAAAAAAAAA3NMyc3MC0jMS80JSi0sTS0ucSxNySzJzEv3yU8vjnf2AQBvJGBnIAAAAA%253D%253D/timespan/P1D`,
          }
        : {
              apiBaseUrl: `https://${env}-admin-api.approvalmax.com`,
              appUrl: `https://devapp${envNumber}.approvalmax.com`,
              accountUrl: `https://account${env}.approvalmax.com`,
              auditLogsUrl: `https://portal.azure.com#@24d5fd06-86ce-40bf-be09-837627e3385d/blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2F88ad0ef2-9fa6-4c5a-b7a4-54a81e804f5f%2Fresourcegroups%2F${env}%2Fproviders%2Fmicrosoft.operationalinsights%2Fworkspaces%2Fam-${env}-workspace/source/LogsBlade.AnalyticsShareLinkToQuery/q/H4sIAAAAAAAAA3NMyc3MC0jMS80JSi0sTS0ucSxNySzJzEv3yU8vjnf2AQBvJGBnIAAAAA%253D%253D/timespan/P1D`,
          };
};

const CONFIG_MAP: Record<
    Env,
    {
        apiBaseUrl: string;
        appUrl: string;
        accountUrl: string;
        auditLogsUrl: string;
    }
> = {
    dev01: getDevEnvConfig('dev01'),
    dev02: getDevEnvConfig('dev02'),
    dev03: getDevEnvConfig('dev03'),
    dev04: getDevEnvConfig('dev04'),
    staging: {
        apiBaseUrl: 'https://test-admin-api.approvalmax.com',
        appUrl: 'https://testapp.approvalmax.com',
        accountUrl: 'https://accounttest.approvalmax.com',
        auditLogsUrl:
            'https://portal.azure.com#@24d5fd06-86ce-40bf-be09-837627e3385d/blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2F88ad0ef2-9fa6-4c5a-b7a4-54a81e804f5f%2Fresourcegroups%2Fstage%2Fproviders%2Fmicrosoft.operationalinsights%2Fworkspaces%2Fam-stage-workspace/source/LogsBlade.AnalyticsShareLinkToQuery/q/H4sIAAAAAAAAA3NMyc3MC0jMS80JSi0sTS0ucSxNySzJzEv3yU8vjnf2AQBvJGBnIAAAAA%253D%253D/timespan/P1D',
    },
    production: {
        apiBaseUrl: 'https://admin-api.approvalmax.com',
        appUrl: 'https://app.approvalmax.com',
        accountUrl: 'https://account.approvalmax.com',
        auditLogsUrl:
            'https://portal.azure.com#@24d5fd06-86ce-40bf-be09-837627e3385d/blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2F88ad0ef2-9fa6-4c5a-b7a4-54a81e804f5f%2Fresourcegroups%2Fprod%2Fproviders%2Fmicrosoft.operationalinsights%2Fworkspaces%2Fam-prod-workspace/source/LogsBlade.AnalyticsShareLinkToQuery/q/H4sIAAAAAAAAA3NMyc3MC0jMS80JSi0sTS0ucSxNySzJzEv3yU8vjnf2AQBvJGBnIAAAAA%253D%253D/timespan/P1D',
    },
};

export const getEnvByHostname = (hostname: string): Env | undefined => {
    return HOSTNAME_TO_ENV_MAP[hostname];
};

export const getConfigForEnv = (env: Env) => {
    return CONFIG_MAP[env];
};

export const getConfig = () => {
    const env = getEnvByHostname(window.location.hostname);

    return getConfigForEnv(env || 'dev04');
};
