import { enabledByParams, QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { ocrApiPaths } from '../paths';
import { UseGetOcrResultParams, UseGetOcrResultPathParams, UseGetOcrResultResponse } from './useGetOcrResult.types';

export const useGetOcrResult = (
    pathParams: UseGetOcrResultPathParams,
    params: UseGetOcrResultParams,
    queryOptions?: QueryOptions<any>
) => {
    return useGetRequest<UseGetOcrResultResponse>(ocrApiPaths.getOcrResult, {
        pathParams: pathParams,
        params,
        queryOptions: {
            enabled: enabledByParams<UseGetOcrResultPathParams>(pathParams),
            ...queryOptions,
        },
    });
};
