import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import { UseAmaxPayConsentDetailsPathParams, UseAmaxPayConsentDetailsResponse } from './useAmaxPayConsentDetails.types';

export const useGetAmaxPayConsentDetails = (
    pathParams: UseAmaxPayConsentDetailsPathParams,
    queryOptions?: QueryOptions<UseAmaxPayConsentDetailsResponse>
) => {
    return useGetRequest<UseAmaxPayConsentDetailsResponse>(amaxPayApiPaths.openBankingConsentDetails, {
        pathParams,
        queryOptions,
    });
};
