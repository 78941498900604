import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { requestsApiPaths } from '../paths';
import { UseGetRequestVersionsPathParams, UseGetRequestVersionsResponse } from './useGetRequestVersions.types';

export const useGetRequestVersions = (
    pathParams: UseGetRequestVersionsPathParams,
    queryOptions?: QueryOptions<UseGetRequestVersionsResponse>
) => {
    return useGetRequest<UseGetRequestVersionsResponse>(requestsApiPaths.getRequestVersions, {
        pathParams,
        queryOptions,
    });
};
