import { QueryOptions, QueryParams } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import {
    UseAmaxPayBankAccountsParams,
    UseAmaxPayBankAccountsPathParams,
    UseAmaxPayBankAccountsResponse,
} from './useAmaxPayBankAccounts.types';

export const useGetAmaxPayBankAccounts = (
    pathParams: QueryParams<UseAmaxPayBankAccountsPathParams>,
    params: UseAmaxPayBankAccountsParams,
    queryOptions?: QueryOptions<UseAmaxPayBankAccountsResponse>
) => {
    return useGetRequest<UseAmaxPayBankAccountsResponse>(amaxPayApiPaths.openBankingAccounts, {
        pathParams,
        params,
        queryOptions,
    });
};
