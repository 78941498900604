import { SearchInput } from '@approvalmax/ui';
import { FC, FormEvent, memo, useCallback } from 'react';

import { messages } from './SearchBar.messages';
import { SearchButton, SearchForm } from './SearchBar.styles';
import { SearchBarProps } from './SearchBar.types';

const SearchBar: FC<SearchBarProps> = (props) => {
    const { onSearch, disabled, placeholder, focusOnMount, value, onChange, withoutButton } = props;

    const submit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            if (!disabled) {
                onSearch && onSearch();
                event.preventDefault();
            }
        },
        [onSearch, disabled]
    );

    return (
        <SearchForm onSubmit={submit}>
            <SearchInput
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                focusOnMount={focusOnMount}
                disabled={disabled}
            />

            {!withoutButton && onSearch && (
                <SearchButton execute={onSearch} disabled={disabled} preset='compact'>
                    {messages.searchButton}
                </SearchButton>
            )}
        </SearchForm>
    );
};

export default memo(SearchBar);
