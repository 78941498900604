import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { requestsApiPaths } from '../paths';
import { UseGetRequestVersionDataPathParams, UseGetRequestVersionDataResponse } from './useGetRequestVersionData.types';

export const useGetRequestVersionData = (
    pathParams: UseGetRequestVersionDataPathParams,
    queryOptions?: QueryOptions<UseGetRequestVersionDataResponse>
) => {
    return useGetRequest<UseGetRequestVersionDataResponse>(requestsApiPaths.getRequestVersionData, {
        pathParams,
        queryOptions,
    });
};
