import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import {
    UseAmaxPayBankAccountDetailsPathParams,
    UseAmaxPayBankAccountDetailsResponse,
} from './useAmaxPayBankAccountDetails.types';

export const useGetAmaxPayBankAccountDetails = (
    pathParams: UseAmaxPayBankAccountDetailsPathParams,
    queryOptions?: QueryOptions<UseAmaxPayBankAccountDetailsResponse>
) => {
    return useGetRequest<UseAmaxPayBankAccountDetailsResponse>(amaxPayApiPaths.openBankingAccountDetails, {
        pathParams,
        queryOptions,
    });
};
