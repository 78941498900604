import { useGetRequest } from 'modules/data/api';

import { supportApiPaths } from '../paths';
import { mapData } from './useGetNetSuiteCacheStatuses.map';
import {
    UseGetNetSuiteCacheStatusesBackend,
    UseGetNetSuiteCacheStatusesResponse,
} from './useGetNetSuiteCacheStatuses.types';

export const useGetNetSuiteCacheStatuses = (companyId: string) => {
    return useGetRequest<UseGetNetSuiteCacheStatusesBackend, UseGetNetSuiteCacheStatusesResponse>(
        supportApiPaths.getNetSuiteCacheStatuses,
        {
            pathParams: { companyId },
            queryOptions: { refetchOnMount: false },
            mapData,
        }
    );
};
