import { ColumnDefinition } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import { AmaxPayPaymentHistoryItem } from 'modules/data';

import { messages } from './AmaxPayPaymentHistoryPage.messages';

export const dataTableColumns: ColumnDefinition<AmaxPayPaymentHistoryItem>[] = [
    {
        id: 'id',
        name: messages.id,
    },
    {
        id: 'createdAt',
        name: messages.createdAt,
        value: (item) => dateTimeHelpers.formatDateTime(item.createdAt),
    },
    {
        id: 'status',
        name: messages.status,
    },
    {
        id: 'isoStatus',
        name: messages.isoStatus,
    },
];
