import { QueryOptions } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import { UseAmaxPayPaymentDetailsPathParams, UseAmaxPayPaymentDetailsResponse } from './useAmaxPayPaymentDetails.types';

export const useGetAmaxPayPaymentDetails = (
    pathParams: UseAmaxPayPaymentDetailsPathParams,
    queryOptions?: QueryOptions<UseAmaxPayPaymentDetailsResponse>
) => {
    return useGetRequest<UseAmaxPayPaymentDetailsResponse>(amaxPayApiPaths.openBankingPaymentDetails, {
        pathParams,
        queryOptions,
    });
};
