import { LoadingBar, LoadingSpinner, Popup } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { toaster } from 'modules/components';
import { useDeleteNetSuiteCache, useGetNetSuiteCacheStatuses, useSyncNetSuiteCache } from 'modules/data';
import { memo, useCallback, useState } from 'react';

import { fieldColumns } from './NetSuiteSyncAndDeleteCachePopup.config';
import { messages } from './NetSuiteSyncAndDeleteCachePopup.messages';
import { DataTableContainer, StyledDataTable } from './NetSuiteSyncAndDeleteCachePopup.styles';
import { NetSuiteCacheAction, NetSuiteSyncAndDeleteCachePopupProps } from './NetSuiteSyncAndDeleteCachePopup.types';

export const NetSuiteSyncAndDeleteCachePopup = memo<NetSuiteSyncAndDeleteCachePopupProps>((props) => {
    const { companyId, onClose, action } = props;
    const [checkedColumns, setCheckedColumns] = useState<string[]>([]);

    const { data, isFetching } = useGetNetSuiteCacheStatuses(companyId);
    const { mutate: syncNetSuiteCache, isLoading: isLoadingSyncNetSuiteCache } = useSyncNetSuiteCache();
    const { mutate: deleteNetSuiteCache, isLoading: isLoadingDeleteNetSuiteCache } = useDeleteNetSuiteCache();

    const onCheckedColumnChange = useCallback((columnIds: string[]) => {
        setCheckedColumns(columnIds);
    }, []);

    const onSubmitButtonClick = () => {
        if (action === NetSuiteCacheAction.sync) {
            syncNetSuiteCache(
                { data: checkedColumns, pathParams: { companyId } },
                {
                    onSuccess: () => {
                        toaster.info(messages.successSyncCache);
                    },
                    onError: () => {
                        toaster.error(messages.failedSyncCache);
                    },
                    onSettled: () => {
                        onClose();
                    },
                }
            );
        } else {
            deleteNetSuiteCache(
                { data: checkedColumns, pathParams: { companyId } },
                {
                    onSuccess: () => {
                        toaster.info(messages.successDeleteCache);
                    },
                    onError: () => {
                        toaster.error(messages.failedDeleteCache);
                    },
                    onSettled: () => {
                        onClose();
                    },
                }
            );
        }
    };

    const isLoading = isLoadingSyncNetSuiteCache || isLoadingDeleteNetSuiteCache;

    return (
        <Popup.DefaultContent
            title={messages.popupTitle}
            buttons={
                <Popup.DefaultContent.Button disabled={checkedColumns.length === 0} execute={onSubmitButtonClick}>
                    {messages.submitButton}
                </Popup.DefaultContent.Button>
            }
        >
            <>
                {isLoading && <LoadingBar />}

                {isFetching ? (
                    <Box spacing='60'>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <DataTableContainer>
                        <StyledDataTable
                            data={data || []}
                            columns={fieldColumns}
                            checkedItems={checkedColumns}
                            onCheckedItemsChange={onCheckedColumnChange}
                            paddingBottom={40}
                        />
                    </DataTableContainer>
                )}
            </>
        </Popup.DefaultContent>
    );
});

NetSuiteSyncAndDeleteCachePopup.displayName = 'NetSuiteSyncAndDeleteCachePopup';
