import { QueryOptions, QueryParams } from '@approvalmax/data';
import { useGetRequest } from 'modules/data/api';

import { amaxPayApiPaths } from '../paths';
import {
    UseAmaxPayPaymentsParams,
    UseAmaxPayPaymentsPathParams,
    UseAmaxPayPaymentsResponse,
} from './useAmaxPayPayments.types';

export const useGetAmaxPayPayments = (
    pathParams: QueryParams<UseAmaxPayPaymentsPathParams>,
    params: UseAmaxPayPaymentsParams,
    queryOptions?: QueryOptions<UseAmaxPayPaymentsResponse>
) => {
    return useGetRequest<UseAmaxPayPaymentsResponse>(amaxPayApiPaths.openBankingPayments, {
        pathParams,
        params,
        queryOptions,
    });
};
